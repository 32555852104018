<template>
  <div>
    <div v-if="isSuccess === 1" class="detail">
      <img src="../../assets/img/pay-success.png" alt="">
      <p class="success">核销成功</p>
    </div>
    <div v-if="isSuccess === 2" class="detail">
      <img src="../../assets/img/pay-er.png" alt="">
      <p class="error">核销失败</p>
    </div>
    <div v-if="isSuccess === 0" class="detail-wait">
      <img src="../../assets/img/wait.png" alt="">
      <p class="wait">正在核销...</p>
    </div>
  </div>
</template>

<script>
import {writeOff} from "../../api/coupon";
import {getToken} from "../../utils/auth";

export default {
  name: "cancel",
  data() {
    return {
      // 是否成功
      isSuccess: 0
    }
  },
  created() {
    // let code = this.$route.query.code
    // localStorage.setItem('code',code)
    if(!getToken()){
      this.$router.replace({name: 'Login'})
    }else {
      this.writeOff()
    }
  },
  methods: {
    writeOff(){
      let code = localStorage.getItem('code')
      writeOff({code}).then(res => {
        this.isSuccess = 1
        localStorage.removeItem('code')
      }).catch(err => {
        this.isSuccess = 2
        localStorage.removeItem('code')
      })
    },
  }
}
</script>

<style scoped>
.detail{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.detail-wait {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.detail-wait img{
  width: 180px;
  height: 180px;
}

.detail img{
  width: 300px;
  height: 180px;
}

.detail .success {
  margin-top: 30px;
  font-size: 28px;
  font-weight: bold;
  color: #3689fb;
  font-family: 宋体;
}

.error {
  margin-top: 30px;
  font-size: 28px;
  font-weight: bold;
  color: #f72949;
  font-family: 宋体;
}

.wait {
  margin-top: 30px;
  font-size: 28px;
  font-weight: bold;
  font-family: 宋体;
}
</style>
