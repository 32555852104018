<template>
  <div class="receive">
    <base-nav-bar :is-back="isBack" title="卡券详情"></base-nav-bar>
    <div class="box-card">
      <div class="record-item" >
      <div class="item-top">
        <img :src="couponData.couponUrl" alt="">
        <div class="content">
          <div class="title">{{ couponData.couponName }}</div>
          <div class="desc">{{ couponData.couponTitle }}</div>
          <div class="time">有效期：{{ parseTime(couponData.startTime,'{y}-{m}-{d}')}} - {{parseTime(couponData.endTime,'{y}-{m}-{d}')}}</div>
        </div>
      </div>
      <div class="item-bottom">
        <span>来自 {{ activityData.activityTitile }} 活动</span>
        <span>领取时间：{{ parseTime(couponData.createTime) }}</span>
      </div>
    </div>
    </div>
    <div class="box-card-code">
      <div class="name">商家扫码核销</div>
      <img class="code" :src="detail.couponUrl" alt="">
      <div class="save">长按二维码保存</div>
    </div>
    <div class="box-card">
      <div class="title">适用门店</div>
      <div class="card">
        <div class="name">门店名称：{{ storeData.storeName }}</div>
        <div class="address">
          <span>门店地址：{{ storeData.storeAddress }}</span>
          <div class="phone" @click="callPhone">
            <img v-if="storeData.storePhone" src="../../assets/img/phone.png" alt="">
            <img v-else src="../../assets/img/tel.png" alt="">
            <span>电话客服</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box-card">
      <div v-html="couponData.notice"></div>
    </div>
  </div>
</template>

<script>
import { getUserCouponById} from "../../api/coupon";

export default {
  name: "Product",
  data() {
    return {
      isBack: true,
      popupShow: false,
      detail:{},
      // 优惠劵信息
      couponData: {},
      storeData:{},
      activityData: {},
      currentIndex:0,
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo(){
      let id = this.$route.query.id
      getUserCouponById({id}).then(res => {
        this.couponData = JSON.parse(res.data.coupon)
        this.storeData = JSON.parse(res.data.store)
        this.activityData = JSON.parse(this.couponData.activity)
        res.data.couponUrl = 'data:image/jpeg;base64,' + res.data.couponUrl
        this.detail = res.data
      })
    },
    callPhone() {
      if(this.storeData.storePhone){
        window.location.href = `tel:${this.storeData.storePhone}`;
      }
    },
  }
}
</script>

<style scoped>
.receive {
  background-color: #f5f6fa;
}
.box-card {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
}

.box-card-code {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.record-item{
  border-radius: 10px;
  box-shadow: 0 2px 2px rgba(0,0,0,.12);
}

.record-item .item-top{
  background: #ffffff;
  padding: 10px;
  border-radius: 10px 10px 0 0 ;
  display: flex;
  align-items: center;
}

.record-item .item-bottom {
  background-color: #f5f6fa;
  color: #999999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 10px 10px;
  padding: 10px;
  font-size: 12px;
}

.item-top img{
  width: 120px;
  height: 100px;
  border-radius: 10px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-top .content .title{
  font-size: 18px;
  font-weight: bold;
  color: #000000;
}

.item-top .content .desc{
  margin-top: 10px;
  color: #666666;
  font-size: 16px;
}

.item-top .content .time{
  margin-top: 20px;
  color: #999999;
  font-size: 14px;
}

.item-top .qp-code {
  margin-left: 20px;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-items: center;*/
}

.item-top .qp-code img {
  width: 50px;
  height: 50px;
}

.item-top .qp-code span{
  font-size: 12px;
}

/*适应门店*/
.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
.card {
  background-color: #f5f6fa;
  padding: 10px;
  border-radius: 10px;
}
.card .address {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.box-card .address img{
  width: 20px;
  height: 20px;
}

.address .phone {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  font-size: 12px;
}
.address .phone img{
  width: 50px;
  height: 50px;
}
</style>
