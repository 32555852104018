<template>
  <div>
    <base-nav-bar :is-back="true" title="优惠劵详细"></base-nav-bar>
    <!-- lu轮播图-------- -->
    <div class="lunbo">
      <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in bannerList" :key="index">
          <img :src="item" alt="图"/> </van-swipe-item>
      </van-swipe>
    </div>
    <!--      详细信息-->
    <div class="detail">
      <div class="goods-list">
        <div class="item">
          <div class="name">{{ detail.couponName }}</div>
          <div class="desc">{{ detail.couponTitle }}</div>
          <div class="receive">
            <span>免费</span>
            <div :class="(detail.limitNumber != detail.userNumber && detail.countNumber != 0) ? 'btn' : 'can'" @click="clickReceive">立即领取</div>
          </div>
        </div>
      </div>
      <div class="box-card">
        <div class="box-item">
          <img src="../../assets/img/time.png" alt="">
          <span class="label">活动时间</span>
          <span class="value">{{ parseTime(detail.startTime,'{y}-{m}-{d}')}} - {{parseTime(detail.endTime,'{y}-{m}-{d}')}}</span>
        </div>
        <div class="box-item">
          <img src="../../assets/img/order.png" alt="">
          <span class="label">剩余卡券</span>
          <div class="progress-bar-container">
            <div class="progress-bar" :style="{ width: progress + '%' }"></div>
          </div>
        </div>
        <div class="number">
          <p>剩余{{ detail.countNumber}}，总共{{ detail.couponNumber }}</p>
        </div>
      </div>
      <div class="title">适用门店</div>
      <div class="box-card">
        <div class="name">门店名称：{{ storeData.storeName }}</div>
        <div class="address">
<!--          <img class="icon" src="../../assets/img/address.png" alt="">-->
          <span>门店地址：{{ storeData.storeAddress }}</span>
          <div class="phone" @click="callPhone">
            <img v-if="storeData.storePhone" src="../../assets/img/phone.png" alt="">
            <img v-else src="../../assets/img/tel.png" alt="">
            <span>电话客服</span>
          </div>
        </div>
      </div>

      <div class="title">使用须知</div>
      <div class="box-card">
        <div v-html="detail.notice"></div>
      </div>
    </div>
  </div>
</template>

<script>

import {getCoupon} from "../../api/coupon";

export default {
  name: "GoodsDetail",
  data(){
    return{
      title: "产品信息",
      isBack: true,
      detail:{
        couponName: '100元优惠劵',
        couponTitle:"无使用门槛，全场通用",
        startTime: new Date(),
        endTime: new Date(),
        notice:"<p>1.仅限本店使用</p><p>2.本券活动优惠不叠加使用</p><p>3.不可兑换现金，不找零</p><p>4.优惠券请在有效期内使用，过期将自动作废</p><p>5.与会员折扣同享，先减后折</p>"
      },
      // 门店信息
      storeData: {
        storeName: '皮尔卡丹女装',
        storeAddress: '保定市朝阳路保百购物广场三楼',
      },
      progress: '99',
      bannerList: ['https://yqcy-1300645565.cos.ap-beijing.myqcloud.com/yqcy/38161724924737711.jpg'],
    }
  },
  created() {
    this.getInfo()
  },
  methods:{
    getInfo(){
      let id = this.$route.query.id
      getCoupon({id}).then(res => {
        this.bannerList = res.data.couponUrl.split(',')
        this.storeData = JSON.parse(res.data.store)
        this.progress = Math.floor((Number(res.data.countNumber) / Number(res.data.couponNumber)) * 100)
        this.detail = res.data
      })
    },
    // 立即领取
    clickReceive() {
      if(this.detail.limitNumber != this.detail.userNumber) {
        this.$router.push({name:'Protocol',query:{id:this.detail.id}})
      }
    },
    //
    callPhone() {
      if(this.storeData.storePhone){
        window.location.href = `tel:${this.storeData.storePhone}`;
      }
    }
  }
}
</script>

<style scoped>
p,div{
  margin: 0;
  padding: 0;
}
/*轮播*/
.lunbo {
  /* background: #c52519; */
}
.my-swipe {
  margin: 0 auto 0;
  width: 100%;
  height: 350px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  background-color: #ffffff;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-swipe .van-swipe-item img {
  width: 100%;
  height: 100%;
}
/*-----------轮播图结束------------*/
/*详细信息*/
.detail{
  background-color: #f4f4f4;
  padding: 10px;
}
.goods-list {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.goods-list img {
  width: 120px;
  height: 100px;
  margin-right: 20px;
}

.goods-list .item {
  flex: 1;
}

.goods-list .item .name {
  font-size: 16px;
  color: #000000;
  font-weight: 700;
}

.goods-list .item .desc {
  font-size: 14px;
  color: #666666;
  margin-top: 10px;
}

.goods-list .item .receive {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 20px;
}

.receive span{
  font-size: 14px;
  color: #f57e53;
}

.receive .btn {
  background: linear-gradient(to right,#cca47e,#e2bd9a);
  color: #ffffff;
  padding: 6px 16px 8px;
  border-radius: 40px;
  font-size: 12px;
}

.receive .can {
  background: #999999;
  color: #ffffff;
  padding: 6px 16px 8px;
  border-radius: 40px;
  font-size: 12px;
}


/* 活动 */
.box-card {
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-top: 10px;
}

.box-item {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.box-item img{
  width: 18px;
  height: 18px;
}

.box-item .label{
  font-size: 16px;
  color: #000000;
  margin: 0 6px;
}

.box-item .value {
  font-size: 16px;
  color: #999999;
}

.number {
  text-align: right;
  font-size: 12px;
}

.box-card .address {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.box-card .address img{
  width: 20px;
  height: 20px;
}

.address .phone {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  font-size: 12px;
}
.address .phone img{
  width: 50px;
  height: 50px;
}

/*进度条*/
.progress-bar-container {
  flex: 1;
  background-color: #eee;
  border-radius: 4px;
  overflow: hidden;
}

.progress-bar {
  height: 10px;
  background: linear-gradient(to right,#cda57f,#e1bc9a);
  transition: width 0.5s ease-in-out;
  border-radius: 4px;
  text-align: center;
  line-height: 10px;
  color: white;
}

.detail .title {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  margin: 10px 0;
}

</style>
