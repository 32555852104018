<template>
  <div class="bg">
    <router-view></router-view>
    <BaseTabBar :selected="selected" :tabBars="tabBars" @onChange="onChange"></BaseTabBar>
  </div>
</template>

<script>
import BaseTabBar from "../../components/BaseTabBar.vue"
export default {
  components: {
    BaseTabBar,
  },
  data() {
    return {
      title: "标题",
      isBack: false,
      selected: 0,
      tabBars: [
        {
          name: "/Index",
          isShowRedDot: false,
          badge: "",
          text: "首页",
          iconPath: require("../../assets/tab/index.png"),
          selectedIconPath: require("../../assets/tab/index_select.png"),
        },
        // {
        //   name: "/Module2",
        //   isShowRedDot: false,
        //   badge: "",
        //   text: "宠物圈",
        //   iconPath: require("../../assets/tab/tab2.png"),
        //   selectedIconPath: require("../../assets/tab/tab2_select.png"),
        // },
        // {
        //   name: "/DemoList",
        //   isShowRedDot: false,
        //   badge: "",
        //   text: "Demo",
        //   iconPath: require("./assets/tab/tab2.png"),
        //   selectedIconPath: require("./assets/tab/tab2_select.png"),
        // },
        {
          name: "/Mine",
          isShowRedDot: false,
          badge: "",
          text: "我的",
          iconPath: require("../../assets/tab/mine.png"),
          selectedIconPath: require("../../assets/tab/mine_select.png"),
        },
        // {
        //   name: "Module4",
        //   isShowRedDot: false,
        //   badge: "哈",
        //   text: "模块四",
        //   iconPath: require("./assets/tab/tab3.png"),
        //   selectedIconPath: require("./assets/tab/tab3_select.png"),
        // },
      ],
    };
  },
  methods: {
    onChange(index) {
      this.tabBars[index].isShowRedDot = this.tabBars[index].isShowRedDot
        ? false
        : false;
    },
  },
  created() { },
};
</script>

<style></style>
