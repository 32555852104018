<template>
  <div class="rule">
    <base-nav-bar :is-back="isBack" title="优惠劵详情"></base-nav-bar>

    <div class="box-card">
      <div class="goods">
        <img :src="detail.couponUrl" alt="">
        <p>{{ detail.couponName }}</p>
      </div>
      <div class="num">
        <p>领取数量</p>
        <div class="step">
          <p>每人限领{{ detail.limitNumber }}张</p>
          <van-stepper v-model="number" theme="round" button-size="24" disable-input :step="1" :max="detail.limitNumber" />
        </div>
      </div>
    </div>

    <div class="bottom">
      <div :class="isSumbit ? 'can' :'btn'" @click="clickSumbit">领取</div>
    </div>
  </div>
</template>

<script>

import {getCoupon, getUserCouponList, receive} from "../../api/coupon";

export default {
  name: "Protocol",
  data() {
    return {
      title:'订单',
      isBack: true,
      detail:{},
      number: '',
      // 是否提交
      isSumbit: false
    }
  },
  created() {
    this.getInfo()
  },
  methods:{
    getInfo(){
      let id = this.$route.query.id
      getCoupon({id}).then(res => {
        this.detail = res.data
      })
    },
    // 提交
    clickSumbit() {
      if(!this.isSumbit) {
        this.isSumbit = true
        receive({couponId: this.detail.id,number:this.number}).then(res => {
          if(res.data != 0) {
            this.$toast.success('领取成功');
            this.$router.replace({name: 'Product',query: {id: res.data}})
            this.isSumbit = false
          }else {
            this.$toast.fail('手慢了~');
            this.$router.go(-1)
            this.isSumbit = false
          }
          // getUserCouponList().then(res => {
          //   if(res.rows.length > 0) {
          //     this.$toast.success('领取成功');
          //     this.$router.push({name: 'Product',query: {id: res.rows[0].id}})
          //     this.isSumbit = false
          //   }else {
          //     this.$toast.success('领取失败');
          //     this.$router.go(-1)
          //     this.isSumbit = false
          //   }
          // })
        })
      }
    },
  }
}
</script>

<style scoped>
.rule{
  padding: 10px;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.box-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 14px;
}

.box-card .goods {
  display: flex;
  align-items: center;
}

.box-card .goods img {
  width: 100px;
  height: 80px;
  border-radius: 10px;
}

.box-card .goods p{
  font-size: 24px;
  font-weight: 600;
  margin-left: 20px;
}

.box-card .num{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.num p{
  font-size: 20px;
}
.num .step {
  display: flex;
  align-items: center;
}

.num .step p{
  color: #999999;
  font-size: 14px;
  margin-right: 10px;
}

.bottom {
  position: fixed;
  left: 0;
  right: 0;
  background-color: #ffffff;
  bottom: 0;
  padding: 10px;
}

.bottom .btn {
  background: linear-gradient(to right,#cca47e,#e2bd9b);
  color: #ffffff;
  text-align: center;
  width: 300px;
  padding: 14px 0;
  margin: 0 auto;
  border-radius: 40px;
}

.bottom .can {
  background: #999999;
  color: #ffffff;
  text-align: center;
  width: 300px;
  padding: 14px 0;
  margin: 0 auto;
  border-radius: 40px;
}

</style>
