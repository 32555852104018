import request from '@/utils/request'

/**
 * 获取活动详情
 * @param id
 * @returns {AxiosPromise}
 */
export function getActivity(id) {
    return request({
        url: `/user/yqActivity/getById/${id}`,
        method: 'get'
    })
}

/**
 * 获取优惠券详情
 * @param params
 * @returns {AxiosPromise}
 */
export function getCoupon(params) {
    return request({
        url: '/user/yqActivity/getCouponById',
        method: 'get',
        params
    })
}

/**
 * 领取优惠劵列表
 * @returns {AxiosPromise}
 */
export function getUserCouponList(params){
    return request({
        url: '/user/yqActivity/getUserCouponList',
        method: 'get',
        params
    })
}

/**
 * 优惠劵详情
 * @param params
 * @returns {AxiosPromise}
 */
export function getUserCouponById(params){
    return request({
        url:'/user/yqActivity/getUserCouponById',
        method: 'get',
        params
    })
}

/**
 * 领取
 * @param data
 * @returns {AxiosPromise}
 */
export function receive(data) {
    return request({
        url: '/user/yqActivity/receive',
        method: 'post',
        data
    })
}

/**
 * 核销
 * @param code
 * @returns {AxiosPromise}
 */
export function writeOff(params){
    return request({
        url: '/user/yqActivity/writeOff',
        method: 'get',
        params
    })
}
