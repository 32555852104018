<template>
  <div class="null">
    <img src="../../assets/img/finish.png" alt="">
    <p>活动已结束</p>
  </div>
</template>

<script>
export default {
  name: "finish"
}
</script>

<style scoped>
.null{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #f6f6f6;
}

.null img{
  width: 180px;
  height: 180px;
}

.null p {
  margin-top: 30px;
  font-size: 28px;
  font-weight: bold;
  color: #a6bbfd;
  font-family: 宋体;
}
</style>
