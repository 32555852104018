<template>
  <div>
    <div class="img-bg">
      <img class="logo" src="@/assets/img/logo.jpg">
    </div>
<!--    <div class="text">欢迎登录晟诺系统</div>-->
    <van-cell-group class="input-bg">
      <van-field v-model="username" label="手机号" placeholder="请输入手机号"  type="phone"
                 label-width="80px" center clearable>
        <img class="icon" src="@/assets/tab/tab1.png" slot="left-icon">
      </van-field>
      <van-field v-model="sms" label="短信验证码" placeholder="请输入短信验证码"
                 label-width="80px" center clearable>
        <img class="icon" src="@/assets/tab/tab2.png" slot="left-icon">
        <template #button>
          <van-button size="small" :disabled="smsText!='发送验证码'"  :type="smsStyle"  @click="sendPhoneSms">{{smsText}}</van-button>
        </template>
      </van-field>
    </van-cell-group>
    <div class="base-submit-btn" style="margin-top:60px;" @click="phoneLogin">登录</div>
  </div>
</template>

<script>
import  {login,sendSms,appLogin} from "@/api/login";
import  {setToken} from "@/utils/auth";

export default {
  name:"Login",
  components: {},
  data() {
    return {
      username: "",
      sms: "",
      smsText:"发送验证码",
      smsStyle:"primary",
      timeNum:59,
      uuid:"",
    };
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      let code = this.$route.query.code || this.getUrlCode().code
      if(code) {
        localStorage.setItem('code',code)
      }
      let id = this.$route.query.type || this.getUrlCode().type
      if(id) {
        localStorage.setItem('activity',id)
      }
    },
    // 发送短信
    sendPhoneSms(){
      if (!this.username){
        this.$toast.success({message:"手机号不能为空",icon:"none"})
        return;
      }

      sendSms({phoneNum:this.username}).then(res=>{

        this.uuid = res.uuid

        if (res.code==200){
          this.$toast.success({message:"发送成功",icon:"none"})

          let timer = setInterval(()=>{
            if (this.timeNum>0){
              this.smsText = `${this.timeNum}秒后发送`
              this.timeNum--
            }else{
              this.smsText = "发送验证码";
              this.timeNum = 59
              clearInterval(timer)
            }

          },1000)
        }else{
          this.$toast.success({message:res.msg,icon:"none"})
        }

      })

    },
    //登录
    phoneLogin(){
      if (!this.username){
        this.$toast.success({message:"请输入手机号",icon:"none"})
        return;
      }
      if (!this.sms){
        this.$toast.success({message:"请输入验证码",icon:"none"})
        return;
      }
      appLogin({phone:this.username,code:this.sms,uuid:this.uuid}).then(res=>{
        this.$toast.success('登录成功');
        setToken(res.token)
        localStorage.setItem('writeOff',res.writeOff)
        if(res.writeOff){
          this.$router.replace({ name: "Cancel" });
        } else {
          this.$router.replace({ name: "Module1" });
        }
        this.$toast.clear();
      })
    },

    // 获取连接上的参数值
    getUrlCode() {
      var url = new URL(window.location.href);
      var searchParams = url.searchParams;
      // 创建一个对象来存储参数
      var params = {};
      let list = []
      searchParams.forEach((value,key) => {
        params[key] = value
      })
      return params
    }
  },

};
</script>

<style>
.img-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}

/*轮播*/
.lunbo {
  /* background: #c52519; */
}
.my-swipe {
  margin: 0 auto 0;
  width: 100%;
  height: 350px;
}
.my-swipe .van-swipe-item {
  color: #fff;
  background-color: #ffffff;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-swipe .van-swipe-item img {
  width: 100%;
  height: 100%;
}

.logo {
  width: 300px;
//height: 100px;
  /* border-radius: 50px; */
  margin-top: 20px;
}

.text {
  width: 100vw;
  text-align: center;
  margin: 30px 0;
}

.input-bg {
  margin: 15px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  padding-bottom: 2px;
}
</style>
