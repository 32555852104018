import Vue from "vue"
import VueRouter from "vue-router"

import Login from "../views/login/login.vue"
import NotFound from "../views/root/NotFound.vue"
import GoodsDetail from "../views/module1/GoodsDetail"
import Module1 from "../views/module1/index.vue"
import Record from "../views/module1/record.vue"
import Main from "../views/root/main"
import Index from "../views/index"
import Mine from "../views/mine"
import Protocol from "../views/module1/Protocol"
import Product from "../views/module1/Product"
import Cancel from "../views/module1/cancel"
import Camera from "../views/module1/camera"
import Finish from "../views/module1/finish"
import Coupon from "../views/module1/coupon"

Vue.use(VueRouter);

const routes = [
  // { path: "/", name: "Root", component: Root },
  { path: "/Login", name: "Login", component: Login },
  { path: "/", name: "Module1", component: Module1 },
  {
    path: "/Main",
    name: "Main",
    component: Main,
    children: [
      {path:'/Index', name:"Index", component: Index},
      {path:'/Mine', name:"Mine", component: Mine, },
    ]
  },
  { path: "/GoodsDetail", name: "GoodsDetail", component: GoodsDetail },
  { path: "/Record", name: "Record", component: Record },
  { path: "/Protocol", name: "Protocol", component: Protocol },
  { path: "/Product", name: "Product", component: Product },
  { path: "/Cancel", name: "Cancel", component: Cancel },
  { path: "/Camera", name: "Camera", component: Camera },
  { path: "/Finish", name: "Finish", component: Finish },
  { path: "/Coupon", name: "Coupon", component: Coupon },

  { path: "/NotFound", name: "NotFound", component: NotFound },
  // 所有未定义路由，全部重定向到404页，必须放在最后
  {
    path: '*',
    redirect: '/404'
  }

];




const router = new VueRouter({
  routes,
  mode: 'hash',
  // base: process.env.BASE_URL,
  // /**
  //  * 路由跳转后页面滚动到顶部
  //  * 详见：https://router.vuejs.org/zh/guide/advanced/scroll-behavior.html
  //  */
  // scrollBehavior: () => ({ y: 0 }),
});




/*

只能永久，需要手动清除
存储：window.localStorage.setItem(name, JSON.stringify(obj))
读取：window.localStorage.getItem(name)
删除：window.localStorage.removeItem(name)
清空：window.localStorage.clear()
*/


import { getToken } from "@/utils/auth"
// 路由前置守卫
router.beforeEach((to, from, next) => {
  next();

  // const { meta, name } = to;
  // const { isLogin } = meta;
  // let token = getToken();

  // token不存在时跳转非登录页，重定向到登录页
  // if (!token && name !== 'Login' && !isLogin) {
  //   next({ name: 'Login' });
  // } else if (token && name === 'Login') {
  //   next({ name: 'Main' });
  // }
  // // 其他场景
  // else next();
});


export default router;
