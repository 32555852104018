let content = `<section data-role="outer" class="article135" style="background-repeat: repeat; background-position: center center; padding: 0px; background-color: rgb(255, 240, 224); background-size: auto; background-image: none; box-sizing: border-box;" data-tplid="119924">
\t<section class="_135editor" data-tools="135编辑器" data-id="105041">
\t\t<section data-role="absolute-layout" data-mode="svg" data-width="375" data-height="467" data-ratio="1.2453333333333334" style="max-width: 100% !important;font-size: 16px;overflow: hidden;grid-template-rows: 100%;grid-template-columns: 100%;display: grid; width: 100%;box-sizing:border-box;">
\t\t\t<section data-role="ratio" style="grid-row-start: 1; grid-column-start: 1; height: 100%;">
\t\t\t\t<svg viewbox="0 0 375 467" style="max-width: 100% !important;pointer-events: none; display: inline-block; width: 100%;  -webkit-tap-highlight-color: transparent; user-select: none;box-sizing:border-box;" xml:space="default"></svg>
\t\t\t</section>
\t\t\t<section data-role="block" style=";max-width: 100% !important;width: 100%;margin-top: 0%;margin-left: 0%;grid-row-start: 1;grid-column-start: 1;height: max-content;line-height: 0; display: block; font-size: 16px;box-sizing:border-box;transform: scale(1);-webkit-transform: scale(1);-moz-transform: scale(1);-o-transform: scale(1);" class="_135editor">
\t\t\t\t<svg style="max-width: 100% !important;display: inline-block; width: 100%;  line-height: 1.6; overflow: visible;box-sizing:border-box;" viewbox="0 0 375 467" xml:space="default">
\t\t\t\t\t<foreignobject data-role="block-content" height="100%" width="100%">
\t\t\t\t\t\t<img data-role="target" style="vertical-align:inherit;width: 100%; vertical-align : top;box-sizing:border-box;max-width:100% !important;" src="https://bcn.135editor.com/files/images/editor_styles/1b4126d0177f46497101935e723c5380.jpg" draggable="false" data-ratio="1.3333333333333333" data-w="750"/>
\t\t\t\t\t</foreignobject>
\t\t\t\t</svg>
\t\t\t</section>
\t\t\t<section data-role="block" style=";max-width: 100% !important;width: 100%;margin-top: 3.2%;margin-left: 11.466666666666667%;grid-row-start: 1;grid-column-start: 1;height: max-content;line-height: 0; display: block; font-size: 16px;box-sizing:border-box;transform: scale(1);-webkit-transform: scale(1);-moz-transform: scale(1);-o-transform: scale(1);" class="_135editor">
\t\t\t\t<svg style="max-width: 100% !important;display: inline-block; width: 100%;  line-height: 1.6; overflow: visible;box-sizing:border-box;" viewbox="0 0 375 88" xml:space="default">
\t\t\t\t\t<foreignobject data-role="block-content" height="100%" width="100%">
\t\t\t\t\t\t<section style="text-align: center;">
\t\t\t\t\t\t\t<section>
\t\t\t\t\t\t\t\t<span style="font-size: 3.4375em; color: #FFDBC2; ;" class="135brush" data-brushtype="text">FASHION</span>
\t\t\t\t\t\t\t</section>
\t\t\t\t\t\t</section>
\t\t\t\t\t</foreignobject>
\t\t\t\t</svg>
\t\t\t</section>
\t\t\t<section data-role="block" style=";max-width: 100% !important;width: 100%;margin-top: 14.133333333333335%;margin-left: 12.8%;grid-row-start: 1;grid-column-start: 1;height: max-content;line-height: 0; display: block; font-size: 16px;box-sizing:border-box;transform: scale(1);-webkit-transform: scale(1);-moz-transform: scale(1);-o-transform: scale(1);" class="_135editor">
\t\t\t\t<svg style="max-width: 100% !important;display: inline-block; width: 100%;  line-height: 1.6; overflow: visible;box-sizing:border-box;" viewbox="0 0 375 42" xml:space="default">
\t\t\t\t\t<foreignobject data-role="block-content" height="100%" width="100%">
\t\t\t\t\t\t<section style="text-align: center;">
\t\t\t\t\t\t\t<section class="">
\t\t\t\t\t\t\t\t<span style="font-size: 1.625em; letter-spacing: 0.125em;" class="135brush" data-brushtype="text"><span style="color:#91821d">秋衣新尚</span></span><span style="font-size: 1.625em; color: #b66831; letter-spacing: 0.125em; ;" class="135brush" data-brushtype="text">/焕新回归</span>
\t\t\t\t\t\t\t</section>
\t\t\t\t\t\t</section>
\t\t\t\t\t</foreignobject>
\t\t\t\t</svg>
\t\t\t</section>
\t\t\t<section data-role="block" style=";max-width: 100% !important;width: 100%;margin-top: 28.53333333333333%;margin-left: 0%;grid-row-start: 1;grid-column-start: 1;height: max-content;line-height: 0; display: block; font-size: 16px;box-sizing:border-box;transform: scale(1);-webkit-transform: scale(1);-moz-transform: scale(1);-o-transform: scale(1);" class="_135editor">
\t\t\t\t<svg style="max-width: 100% !important;display: inline-block; width: 100%;  line-height: 1.6; overflow: visible;box-sizing:border-box;" viewbox="0 0 375 360" xml:space="default">
\t\t\t\t\t<foreignobject data-role="block-content" height="100%" width="100%">
\t\t\t\t\t\t<section style="margin: 0px auto; padding: 0.625em 0px; box-sizing: border-box;">
\t\t\t\t\t\t\t<section>
\t\t\t\t\t\t\t\t<section style="display: flex;justify-content: space-between;">
\t\t\t\t\t\t\t\t\t<section style="width: 15%;flex-shrink: 0;display: flex;align-items: flex-end;box-sizing:border-box;max-width:15% !important;">
\t\t\t\t\t\t\t\t\t\t<section style="max-width: 100% !important;height: 65%;width: 100%;display: flex;justify-content: flex-end;align-items: flex-end;box-sizing:border-box;">
\t\t\t\t\t\t\t\t\t\t\t<section class="assistant" style="border-left: 0.0625em solid #f3bd96; width: 20%; height: 100%; overflow: hidden;box-sizing:border-box;max-width:20% !important;"></section>
\t\t\t\t\t\t\t\t\t\t</section>
\t\t\t\t\t\t\t\t\t</section>
\t\t\t\t\t\t\t\t\t<section style="width: 70%;box-sizing:border-box;max-width:70% !important;">
\t\t\t\t\t\t\t\t\t\t<section style="max-width: 100% !important;width: 100%;box-sizing:border-box;" class="">
\t\t\t\t\t\t\t\t\t\t\t<img src="https://bexp.135editor.com/files/users/791/7912146/202408/krSuUqwj_ICWO.jpg?auth_key=1725206399-0-0-d49832179056921d9b96173325864445" style="vertical-align: baseline; width: 100%;box-sizing:border-box;max-width:100% !important;" alt="891f152d054790f4567b39c3ada8881.jpg" draggable="false" data-ratio="1.3416666666666666" data-w="1080"/>
\t\t\t\t\t\t\t\t\t\t</section>
\t\t\t\t\t\t\t\t\t</section>
\t\t\t\t\t\t\t\t\t<section style="width: 15%;flex-shrink: 0;display: flex;align-items: flex-end;box-sizing:border-box;max-width:15% !important;">
\t\t\t\t\t\t\t\t\t\t<section style="max-width: 100% !important;height: 65%;width: 100%;display: flex;justify-content: flex-start;align-items: flex-end;box-sizing:border-box;">
\t\t\t\t\t\t\t\t\t\t\t<section class="assistant" style="border-right: 0.0625em solid #f3bd96; width: 20%; height: 100%; overflow: hidden;box-sizing:border-box;max-width:20% !important;"></section>
\t\t\t\t\t\t\t\t\t\t</section>
\t\t\t\t\t\t\t\t\t</section>
\t\t\t\t\t\t\t\t</section>
\t\t\t\t\t\t\t\t<section>
\t\t\t\t\t\t\t\t\t<section style="padding: 0.571429em 0px 0px; font-size: 0.875em; color: #b66831; text-align: center; box-sizing: border-box;">
\t\t\t\t\t\t\t\t\t\t<p style="line-height: 2.5em;">
\t\t\t\t\t\t\t\t\t\t\t<br/>
\t\t\t\t\t\t\t\t\t\t</p>
\t\t\t\t\t\t\t\t\t</section>
\t\t\t\t\t\t\t\t</section>
\t\t\t\t\t\t\t</section>
\t\t\t\t\t\t</section>
\t\t\t\t\t</foreignobject>
\t\t\t\t</svg>
\t\t\t</section>
\t\t</section>
\t</section>
\t<section class="_135editor">
\t\t<section style="text-align: center; line-height: 1.75em;font-size: 14px; color: #b66831;" class="">
\t\t\t<section class="135brush" data-autoskip="1">
\t\t\t\t<p style="line-height:1.5em">
\t\t\t\t\t<span style="font-size: 18px;">9月宠粉月来了</span>
\t\t\t\t</p>
\t\t\t</section>
\t\t\t<section class="135brush" data-autoskip="1">
\t\t\t\t<p style="line-height:1.5em">
\t\t\t\t\t<span style="color: #b66831; text-align: center; caret-color: #ff0000; background-color: #fff0e0; text-decoration-thickness: initial; font-size: 18px; display: inline !important;font-family:mp-quote, -apple-system-font, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;">将为会员提供专属权的礼券</span>
\t\t\t\t</p>
\t\t\t</section>
\t\t\t<section class="135brush" data-autoskip="1">
\t\t\t\t<p style="line-height:1.5em">
\t\t\t\t\t<span style="color: #b66831; text-align: center; caret-color: #ff0000; background-color: #fff0e0; text-decoration-thickness: initial; font-size: 18px; display: inline !important;font-family:mp-quote, -apple-system-font, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;">礼品以及各种惊喜福利</span>
\t\t\t\t</p>
\t\t\t</section>
\t\t\t<section class="135brush" data-autoskip="1">
\t\t\t\t<p style="line-height:1.5em">
\t\t\t\t\t<span style="color: #b66831; text-align: center; caret-color: #ff0000; background-color: #fff0e0; text-decoration-thickness: initial; font-size: 18px; display: inline !important;font-family:mp-quote, -apple-system-font, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;">让你体验到何为真正的尊贵</span>
\t\t\t\t</p>
\t\t\t</section>
\t\t</section>
\t</section>
\t<section class="_135editor" data-role="paragraph" data-width="74%" style="width: 74%; flex: 0 0 74%; margin-left: auto; margin-right: auto;box-sizing:border-box;max-width:74% !important;">
\t\t<p >
\t\t\t<br/>
\t\t</p>
\t\t<p>
\t\t\t<br/>
\t\t</p>
\t</section>
</section>`

export  default  content
