import { ActionSheet, Button, Cell, CellGroup, Stepper, CountDown, Dialog, Field, Form, Grid, GridItem, Icon, List, NavBar, Popup, PullRefresh, Search, Swipe, SwipeItem, Tab, Tabbar, TabbarItem, Tabs, Toast, Progress } from 'vant'
import Vue from 'vue'


Vue.use(NavBar);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(Button);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Form);
Vue.use(Field);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(ActionSheet);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Search);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(CountDown);
Vue.use(Popup);
Vue.use(Progress);
Vue.use(Stepper);
