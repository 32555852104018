<template>
  <div class="hong-back">
    <div class="lunbo">
      <van-swipe class="my-swipe" :autoplay="5000" indicator-color="white">
        <van-swipe-item
          v-for="(item, index) in activityData.activityUrl"
          :key="index"
        >
          <img :src="item" alt="图" />
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="goods">
      <div class="title">{{ activityData.activityTitile }}</div>
      <div class="time">
        <img src="../../assets/img/time.png" alt="" />
        <p>
          活动时间：<span
            >{{ parseTime(activityData.startTime) }} -
            {{ parseTime(activityData.endTime) }}</span
          >
        </p>
      </div>
      <div
        class="goods-list"
        v-for="(item, index) in activityData.list"
        :key="index"
        @click="clickDetail(item.id)"
      >
        <img :src="item.couponUrl" alt="" />
        <div class="item">
          <div class="name">{{ item.couponName }}</div>
          <div class="desc">{{ item.couponTitle }}</div>
          <div class="receive">
            <span>免费</span>
            <div :class="item.limitNumber != item.userNumber ? 'btn' : 'can'">
              立即领取
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="desc">
      <div v-html=""></div>
    </div>

    <div class="detail">
      <!--      <div class="content">-->
      <!--        -->
      <!--      </div>-->
      <div v-html="activityData.activityContent"></div>
      <img src="../../assets/img/support.png" alt="" class="support" />
    </div>

    <div class="custom" @click="clickCustom">客服</div>
    <div class="order" @click="clickOrder">
      <img src="../../assets/img/order.png" alt="" />
      <span>领取记录</span>
    </div>

    <van-popup
      round
      v-model="show"
      closeable
      close-on-click-overlay
      :style="{ width: '80%' }"
    >
      <div class="popup-title">电话咨询</div>
      <div class="popup-tel">电话：0312-3339230</div>
      <div class="popup-btn" @click="callPhone">拨打</div>
    </van-popup>
  </div>
</template>

<script>
import { getActivity, getCoupon, getUserCouponList } from "../../api/coupon";
import { getToken } from "../../utils/auth";
import content from "../../utils/content";

export default {
  data() {
    return {
      storeId: "",
      // 活动信息
      activityData: {
        activityUrl: [
          "https://yqcy-1300645565.cos.ap-beijing.myqcloud.com/yqcy/29631724928904280.jpg",
        ],
        activityTitile: "秋衣新尚,焕新回归",
        startTime: new Date(),
        endTime: new Date(),
        activityContent: content,
      },
      // 弹出层
      show: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let code = this.$route.query.code || this.getUrlCode().code;
      if (code) {
        localStorage.setItem("code", code);
      }
      let id = this.$route.query.type || this.getUrlCode().type;
      if (id) {
        localStorage.setItem("activity", id);
      }
      let writeOff = localStorage.getItem("writeOff");
      if (writeOff == "true") {
        this.$router.replace({ name: "Cancel" });
        return;
      }
      this.getActivityData();
    },
    getActivityData() {
      let id = localStorage.getItem("activity");
      if (!id) {
        this.$router.replace({ name: "Finish" });
        return;
      }
      getActivity(id).then((res) => {
        res.data.activityUrl = res.data.activityUrl.split(",");
        this.activityData = res.data;
        this.storeId = res.data.storeId;
      });
    },
    // 跳转商品详情
    clickDetail(id) {
      this.$router.push({ name: "GoodsDetail", query: { id: id } });
    },
    // 跳转订单记录
    clickOrder() {
      this.$router.push({
        name: "Record",
        query: {
          storeId: this.storeId, // 假设this.storeId已经在当前组件中定义
        },
      });
    },
    // 客服
    clickCustom() {
      this.show = true;
    },
    // 拨打
    callPhone() {
      let phone = "0312-3339230";
      window.location.href = `tel:${phone}`;
    },
    // 获取连接上的参数值
    getUrlCode() {
      var url = new URL(window.location.href);
      var searchParams = url.searchParams;
      // 创建一个对象来存储参数
      var params = {};
      let list = [];
      searchParams.forEach((value, key) => {
        params[key] = value;
      });
      return params;
    },
  },
};
</script>

<style lang="less" scoped>
// 看 vue.config.js
* {
  margin: 0;
  padding: 0;
}

//banner图
.banner {
  width: 100%;
  height: 300px;
}
.banner img {
  width: 100%;
  height: 100%;
}

// 商品信息
.goods {
  background: linear-gradient(to bottom, #f9974c, #f9974c);
  padding: 10px;
}

.goods .title {
  font-size: 20px;
  color: #000000;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}

.goods .time {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 20px 10px;
  border-radius: 10px;
}

.goods .time img {
  width: 14px;
  height: 14px;
  margin: 2px 10px 0 0;
}

.goods .time p {
  font-size: 12px;
  color: #333333;
}

.goods .time spam {
  font-size: 12px;
  color: #666666;
}

.goods .goods-list {
  background-color: #ffffff;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.goods-list img {
  width: 120px;
  height: 100px;
  margin-right: 20px;
  border-radius: 10px;
}

.goods-list .item {
  flex: 1;
}

.goods-list .item .name {
  font-size: 16px;
  color: #000000;
  font-weight: 700;
}

.goods-list .item .desc {
  font-size: 14px;
  color: #666666;
  margin-top: 10px;
}

.goods-list .item .receive {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 20px;
}

.receive span {
  font-size: 14px;
  color: #f57e53;
}

.receive .btn {
  background: linear-gradient(to right, #cca47e, #e2bd9a);
  color: #ffffff;
  padding: 6px 16px 8px;
  border-radius: 40px;
  font-size: 12px;
}

.receive .can {
  background: #999999;
  color: #ffffff;
  padding: 6px 16px 8px;
  border-radius: 40px;
  font-size: 12px;
}

.detail {
  background: #ffca00;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detail .content {
  background-color: #ffffff;
  padding: 20px;
}

// 领取记录
.order {
  position: fixed;
  right: 0;
  top: 300px;
  z-index: 99;
  background: linear-gradient(to bottom, #cca57f, #e1bb99);
  color: #ffffff;
  font-size: 12px;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.order img {
  width: 24px;
  height: 24px;
}

// 客服
.custom {
  position: fixed;
  right: 0;
  top: 200px;
  z-index: 99;
  background: linear-gradient(to bottom, #cca57f, #e1bb99);
  color: #ffffff;
  font-size: 16px;
  border-radius: 30px 0 0 30px;
  padding: 4px 0;
  width: 60px;
  display: flex;
  justify-content: center;
  border: solid 1px #ffffff;
}

// 弹窗
.popup-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 30px 0;
}

.popup-tel {
  text-align: center;
  font-size: 18px;
}

.popup-btn {
  margin: 20px auto;
  width: 200px;
  height: 40px;
  color: #ffffff;
  background: linear-gradient(to bottom, #cca57f, #e1bb99);
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

// 底部logo
.support {
  width: 140px;
  height: 50px;
  margin: 20px auto;
}
</style>
