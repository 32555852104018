<template>
  <div class="receive">
    <base-nav-bar :is-back="isBack"></base-nav-bar>
    <div class="success">
      <img src="../../assets/img/success.png" alt="">
      <p>领取成功</p>
    </div>
    <div class="name">{{ couponData.couponName }}</div>
    <img class="code" :src="detail.couponUrl" alt="">
    <div class="save">长按二维码保存</div>
    <div class="desc">券码：{{ detail.couponCode }}</div>
    <div class="desc">{{ couponData.couponTitle }}</div>
    <div class="time">有效期：{{ parseTime(detail.stareTime,'{y}-{m}-{d}')}} - {{parseTime(detail.endTime,'{y}-{m}-{d}')}}</div>
  </div>
</template>

<script>
import { getUserCouponById} from "../../api/coupon";

export default {
  name: "Product",
  data() {
    return {
      isBack: true,
      popupShow: false,
      detail:{},
      // 优惠劵信息
      couponData: {},
      currentIndex:0,
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo(){
      let id = this.$route.query.id
      getUserCouponById({id}).then(res => {
        this.couponData = JSON.parse(res.data.coupon)
        res.data.couponUrl = 'data:image/jpeg;base64,' + res.data.couponUrl
        this.detail = res.data
      })
    },
  }
}
</script>

<style scoped>
.receive {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success {
  display: flex;
  align-items: center;
}

.success img {
  width: 50px;
  height: 50px;
}

.success p {
  font-size: 28px;
  font-weight: bold;
  margin-left: 20px;
}

.name {
  font-size: 16px;
  color: #888888;
  margin-top: 50px;
}

.code {
  width: 250px;
  height: 250px;
  margin-top: 10px;
}

.save {
  margin-top: 10px;
  font-size: 12px;
  color: #888888;
}

.desc {
  font-size: 16px;
  color: #888888;
  margin-top: 20px;
}

.time {
  font-size: 14px;
  color: #888888;
  margin-top: 20px;
}
</style>
